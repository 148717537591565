$skyblue: #D24848;
$green: #D24848;
$blue: #84051a;
$primary: #266085;
$text: #2b2a29;
$text-light: #7B7B7B;
$text-white: #fff;
$footer-bg: #383838;
$grid-gutter-width: 14px;
$input-btn-padding-y-lg: 10.5px;
$input-btn-font-size-lg: 18px;
$input-bg: #f1f3f8;
$input-border-color: $input-bg;
$input-placeholder-color: #9eaabd;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500,700&display=swap');

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

* {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
}

@import 'node_modules/bootstrap/scss/bootstrap-grid';

@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/carousel';

@import './fonts';

@import './typo';

@import './section';
@import './header';
@import './footer';
@import './jumbtron';
@import './servise-item';
@import './service-box';
@import './reviews';
@import './company';
@import './form-box';
@import './tabs-menu';
@import './tabs-preview';


* {
    box-sizing: border-box;
}

body {
    font-size: 14px;
    line-height: 26px;
    font-family: 'Montserrat', sans-serif;

    background: $skyblue;
    font-weight: 400;
}

.wrapper {
    min-width: 1140px;
    padding-top: 126px;

    & .container-fluid {
        width: 1170px;
    }
}

button,
input,
select,
textarea {
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-lg {
    padding: 17px 28px;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}
.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-outline-primary:hover {
    color: #fff;
    font-weight: 300;
}
.btn-outline-primary {
    font-weight: 300;
}

/* --------------------------------

xpopup

-------------------------------- */

.cd-popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(94, 110, 141, 0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
    z-index: 9999;
}
.cd-popup.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
}
.cd-popup-container {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: 4em auto;
    background: #FFF;
    border-radius: .25em .25em .4em .4em;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.cd-popup-container p {
    padding: 3em 1em;
}
.cd-popup-container .cd-buttons:after {
    content: "";
    display: table;
    clear: both;
}
.cd-popup-container .cd-buttons li {
    float: left;
    width: 50%;
    list-style: none;
}
.cd-popup-container .cd-buttons a {
    display: block;
    height: 60px;
    line-height: 60px;
    text-transform: uppercase;
    color: #FFF;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
}
.cd-popup-container .cd-buttons li:first-child a {
    background: #fc7169;
    border-radius: 0 0 0 .25em;
}
.no-touch .cd-popup-container .cd-buttons li:first-child a:hover {
    background-color: #fc8982;
}
.cd-popup-container .cd-buttons li:last-child a {
    background: #b6bece;
    border-radius: 0 0 .25em 0;
}
.no-touch .cd-popup-container .cd-buttons li:last-child a:hover {
    background-color: #c5ccd8;
}
.cd-popup-container .cd-popup-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
}
.cd-popup-container .cd-popup-close::before, .cd-popup-container .cd-popup-close::after {
    content: '';
    position: absolute;
    top: 12px;
    width: 14px;
    height: 3px;
    background-color: #8f9cb5;
}
.cd-popup-container .cd-popup-close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 8px;
}
.cd-popup-container .cd-popup-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 8px;
}
.is-visible .cd-popup-container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
@media only screen and (min-width: 1170px) {
    .cd-popup-container {
        margin: 8em auto;
    }
}
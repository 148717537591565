.header {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;

    background: $skyblue;

    z-index: 100;

    &__logo {
        height: 48px;
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;

        list-style: none outside;

        font-size: 18px;
        line-height: 24px;

        color: $text-white;

        & a {
            text-decoration: none;

            color: $text-white;
        }

        & li {
            padding: 0 15px;
        }
    }

    &__order {
        padding: 11px 40px;

        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }
}

.section {
  padding-top: 100px;
  padding-bottom: 70px;

  &--top {
    position: relative;
    padding-top: 60px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1;
      display: block;
      width: 710px;
      height: 642px;
      background: url('../img/top.png') no-repeat 0 0;
    }

    & .container-jumbtron {
      position: relative;
      z-index: 10;
    }
  }

  &--white {
    background-color: #fff;

    &__title {


      color: $text !important;
    }
  }

  &--formbox {
    padding-top: 0;
  }

  &--reviews {
    position: relative;
    min-height: 906px;
    background-color: #fff;
    background-image: url('../img/reviews.png');
    background-repeat: repeat-x;
    background-position: 0 50%;
    padding-top: 120px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background: url('../img/reviews-people.png') no-repeat 50% 50%;
    }

    &__title {
      color: $text !important;
    }
  }

  &--company {
    padding-bottom: 0;

    &__title {
      color: $text !important;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: $text-white;
  }

  &__subtitle {
    margin-top: -20px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $text-white;
  }
}
